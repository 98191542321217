/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,900&display=swap');

body {
    background-color: #000;
    font-family: 'Roboto', sans-serif;
}


ul,ol{
    padding:0px;
    margin: 0px;
    li{
      
        line-height: 30px;
        a{
            text-decoration: none;
        }
    }
}
.topheader{
    width:100%;
    .title-content{
        p{
            color:#fff;
        }
    }
}
